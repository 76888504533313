<template>
  <!-- IP列表 -->
  <div class="listBox" v-loading="loading">
    <div class="listTitle">
      <div class="name">IP名称</div>
      <div class="lables">标签/热词</div>
      <!-- <div class="index">IP宇宙指数</div> -->
      <div class="price">价格区间</div>
      <div class="operas">操作</div>
    </div>

    <div v-if="ipList.length > 0">
      <div class="listItems" v-for="item in ipList" :key="item.id">
        <IpListItem
          :item="item"
          @HandleDetail="handleDetail"
          @HandleCollection="collection"
          @HandleCompare="compare"
        ></IpListItem>
      </div>
      <div class="page">
        <mo-paging
          :page-index="page.page"
          :total="page.totalNum"
          :page-size="page.pageSize"
          @change="pageChange"
        ></mo-paging>
      </div>
    </div>
    <div v-else class="hasntData">该资源平台已完成直连，暂未上架到网站，获取资料请联系页面下方IP宇宙客服企业微信</div>
  </div>
  <!-- 对比二维码 -->
  <transition name="fadeInLeft">
  	<qrcode ref="qrcode"></qrcode>
  </transition>
</template>

<script>
import IpListItem from "@/views/ipdetail/IpListItem.vue";
import MoPaging from "@/components/Paging.vue";
import qrcode from '@/views/home/qrcode.vue'
import useStore from '@/store/index.js'
import tracker from '@/common/tracker.js';
export default {
  props: {
    ipsData: Object,
  },
  data() {
    return {
      loading:false,
      ipList: [],
      page: {
        page: 1,
        pageSize: 10,
      },
      oldVal:{data:{}}
    };
  },
  components: {
    IpListItem,
    MoPaging,
    qrcode,
  },
  methods: {
    ifCompare(object1, object2) {
      // 判断两对象是否相等
      var o1keys = Object.keys(object1);
      var o2keys = Object.keys(object2);
      if (o2keys.length !== o1keys.length) return false;
      for (let i = 0; i <= o1keys.length - 1; i++) {
        let key = o1keys[i];
        if (!o2keys.includes(key)) return false;
        if (object2[key] !== object1[key]) return false;
      }
      return true;
    },
    //从page组件传递过来的当前page
    pageChange(page) {
      this.page.page = page;
      this.$emit("change", this.page.page);
    },
    handleDetail(e) {
      // 打开一个页面
		let iid = e.currentTarget.dataset["iid"];
		this.utils.goto(this,'/detail',{iid})
    
    },
    pageChange(page) {
      this.page.page = page;
      this.loadItems('page'); 
    },
    collection(e) {
      console.log(e);
      let collection = e.currentTarget.dataset["collection"],
          ipid = e.currentTarget.dataset["id"];
      this.loading = true;
      if (collection == 0) {
        // 收藏
        console.log(0);
        this.API.collect({ ipid }, (res) => {
			if(res.code==0){
				// 刷新列表
				this.loadItems();
				this.loading = false;
			}else if(res.code==5){
				this.loading = false;
				this.store.setCurComp('login');
			}
          console.log(res);
          
        });
      } else {
        // 取消收藏
        console.log(1);
        this.API.cancel_collect({ ipid }, (res) => {
          console.log(res);
          // 刷新列表
		  if(res.code==0){
				// 刷新列表
				this.loadItems();
				this.loading = false;
			}else if(res.code==5){
				this.loading = false;
				this.store.setCurComp('login');
			}
        });
      }
      tracker({
        url: this.$route.path,
        opType: collection == 0 ? 3 : 4,
        ipId: ipid,
      })
    },
    compare(e) {
      console.log("弹出二维码");
      this.$refs.qrcode.showQrcode(1);
    },
    qrcode_hide() {
      this.$refs.qrcode.hideQrcode();
    },
    loadItems(source){
        // 通过type 判断调用哪个数据列表
        if (this.ipsData.type == 2) {
            this.API.collection_list( { page: this.page.page, pageSize: this.page.pageSize }, (res) => {
                    // 获取基本信息
                    this.ipList = res.data.list;
                    this.page.totalNum = res.data.total;
                    this.loading = false;
                }
            );
        } else if (this.ipsData.type == 1) { 
            let data = { page: this.page.page, pageSize: this.page.pageSize }
            Object.assign(data, this.ipsData.data) 
            console.log(data);
            const {page,pageSize,...searchInfo} = data;
            if(source !== 'page' && Object.keys(searchInfo).length !== 0 ){
              tracker({opType:2,searchInfo})
            }
            this.API.ip_filter_list(data, (res) => {
                if (res.code == 0) {
                    this.ipList = res.data.data;
                    this.page.totalNum = res.data.total;
                }
                console.log(res);
            });
        }
    }
  }, 
  watch:{
      ipsData:{
          handler(val){ 
            // console.log("~~~~ipsData~~~~") 
            // console.log(val.data);
            // console.log(this.oldVal.data);
            if(!this.ifCompare(val.data,this.oldVal.data)){
                this.oldVal.data = val.data;
                this.loadItems(); 
            }
          },
          deep: true 
      }
  },
  created() {
    this.page = this.ipsData.page;
    this.loadItems(); 
	this.store = useStore()
  },
};
</script>

<style lang="less" scoped>
.listBox {
  width: 1340px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 25px 120px 25px;
  box-sizing: border-box;
  margin-top: 16px;
  .listTitle {
    width: 1318px;
    height: 58px;
    background: #f6f8fc;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    div {
      height: 58px;
      line-height: 58px;
      font-size: 14px;
      font-weight: 500;
      color: #0b0b0b;
    }
    .name {
      width: 363px;
      padding-left: 60px;
      box-sizing: border-box;
    }
    .lables {
      width: 378px;
      padding-left: 18px;
      box-sizing: border-box;
    }
    .index {
      width: 177px;
    }
    .price {
      width: 220px;
    }
    .operas {
      width: 168px;
    }
  }
  .listItems {
    width: 1318px;
  }

  .hasntData {
    height: 58px;
    line-height: 58px;
    font-size: 14px;
    font-weight: 500;
    color: #0b0b0b;
    padding: 5px;
  }
  .page{
    margin: 40px;
    display: flex;
    justify-content:flex-end;
  }
}
</style>
