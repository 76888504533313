<template>
    <div class="ipListItem" :data-iid='item.id' v-on:click="HandleDetail"> 
        <div class="ipName">
            <div class="head">
                <img :src='item.logo' /> 
            </div>
            <div class="text">
                <div class="name">{{item.name}}</div>
                <div class="tag">{{item.ip_type_level1}}</div>
				<el-tooltip placement="top"  effect="light">
				    <template #content>{{item.desc}}</template>
				    <div class="desc">{{item.desc}}</div>
				</el-tooltip>
                
            </div> 
        </div>
        <div class="lables">
            <div v-for="(lable,index) in item.tags" class="lable" :key="[index,'-',lable]" :data-key="[index,'-',lable]">
                {{lable}}
            </div>
        </div>
        <!-- <div class="index">
            {{item.index}}
        </div> -->
        <div class="price">
            {{item.cooperation_price_down}}—{{item.cooperation_price_up}}
        </div>
        <div class="operas">
            <!-- <div v-for='(opera) in item.operas' class="opera">
                <span :class="opera.text + ' '+ opera.value"></span>
                <span class="text">{{opera.name}}</span>
            </div> -->
			<div class="opera" @click.stop="HandleCompare">
			    <span class='compare false'></span>
			    <span class="text">对比</span>
			</div>
            <div class="opera" :data-collection="item.is_collection" :data-id="item.id"  @click.stop="HandleCollection">
                <span :class="'collection '+ (item.is_collection == 0 ? 'false':'true') "></span>
                <span class="text">{{item.is_collection == 1 ?'取消收藏':'收藏'}}</span>
            </div>
           
        </div>  
    </div>
</template>

<script> 
    export default {
        name:'IpListItem',
        props:{
            item:Object 
        },
        methods : {
            HandleDetail(e){
                this.$emit('HandleDetail',e)
            },
            HandleCollection(e){ 
                this.$emit("HandleCollection",e)
            },
            HandleCompare(e){ 
                this.$emit("HandleCompare",e)
            } 
            
        }
    }  
</script>

<style lang="less" scoped>
    .ipListItem{display: flex;justify-content: space-around;padding: 20px 30px 30px;box-sizing: border-box;border-bottom: 1px solid #f1f3f6;align-items: center;cursor: pointer;
        .ipName{width:363px;display: flex;
            .head{margin:10px;
                &:hover{opacity:0.9;}
                img{width: 60px;height: 60px;cursor:pointer;}
            }
            .text{
                .name{ height: 20px;font-size: 14px; font-weight: 500;color: #040404;line-height: 20px;margin-bottom:6px;}
                .tag{border-radius: 4px;border: 1px solid rgba(245, 99, 11, 0.3);font-size: 12px;font-weight: 400;color: #F5630B;line-height: 20px;padding: 0 6px;margin-bottom: 6px;display: inline-block;}
                .desc{width: 240px;font-size: 12px; font-weight: 400;color: #888888;line-height: 22px;overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}
            } 
        }

        .lables{
            width:378px;padding-right: 50px;box-sizing: border-box;
            .lable{border-radius: 4px;border: 1px solid #6A7084;font-size: 12px;font-weight: 400;color: #6A7084;line-height: 16px;display: inline-block;padding: 4px 8px;margin:6px;cursor: pointer;}
        }

        .index{width:177px;height: 20px;font-size: 14px; font-weight: 400;color: #0B0B0B;line-height: 20px;} 
        .price{width:220px;height: 20px;font-size: 14px; font-weight: 400;color: #0B0B0B;line-height: 20px;}
        .operas{width:168px;
            .opera{line-height: 16px;height: 16px;padding: 10px 0;display: flex;justify-items: center;cursor: pointer;margin-bottom: 20px;
                span{display: inline-block;
                    &.collection{width:20px;height: 16px;
                        background:url(../../assets/images/ip/star.jpg) no-repeat center;
                        &.true{
                            background:url(../../assets/images/ip/star_.jpg) no-repeat center;
                        }
                    }

                    &.compare{width:20px;height: 16px;
                        background:url(../../assets/images/ip/vs.jpg) no-repeat center;
                        &.true{
                            background:url(../../assets/images/ip/vs_.jpg) no-repeat center;
                        }
                    }

                    &.text{padding: 0 10px;line-height: 16px;}
                }
            }
        }
    }
</style>